import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/Section';
import LinkButton from '../components/LinkButton';
import {DesaturatedMainImageSection} from '../components/MainImageSection';
import { MainTitle, SectionTitle }  from '../components/Titles';

import { dimensions, colors } from '../shared/styles';
import makeBlogArticlePath from "../shared/makeBlogArticlePath"


const MainSection = styled(Section)`
  max-width : 1000px;
`;

const ArticleContent = styled.div`
  margin-top : 15rem;

  & h1 {
    line-height : 8rem;
  }

  & h2 {
    margin-top : 10rem;
    font-size : 4rem;
  }

  & h3 {
    margin-top : 6rem;
    font-size : 3rem;
  }

  & h4 {
    margin-top : 4rem;
    font-size : 2.6rem;
  }

  & ul, & ol {
    margin-block-start: 4rem;
    margin-block-end: 4rem;
    margin-inline-start: 3rem;
    margin-inline-end: 3rem;
    padding-inline-start: 5rem;    
  }

  & p {
    font-size : 2.2rem;
    line-height : 3.3rem;
    text-align : justify;
    margin : 4rem 0 4rem 0;
  }

  & li {
    font-size : 2.2rem;
    line-height : 3.3rem;
    text-align : justify;
    margin : 2rem 0 2rem 0;
  }

  & img {
    max-width : 1000px;
    width : 100%;
  }

  @media screen and (max-width : 1000px) {
    max-width : 100%;
    margin-left : 4rem;
    margin-right : 4rem;

    & img {
      max-width : 100%;
    }
  }

`;

const SeparatorContainer = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  margin-top : 10rem;
  margin-bottom : 6rem;
`;

const SeparatorText = styled.span`
  font-weight : bold;
  color : ${colors.blueGrey};
  font-size : 2rem;
`;

const Separator = styled.span`
  border-bottom : 2px solid ${colors.lightGrey};
  flex : 1;
  margin-left : 5rem;
  margin-right : 5rem;

  &.wide {
    margin-left : 0;
  }
`;

const Avatar = styled.img`
  width : 6rem;
  height : 6rem;
  border-radius : 3rem;
  object-fit : cover;
  margin-right : 3rem;
`

const ContactSection = styled(Section)`
  text-align : center;
  margin-bottom : 18rem;
`
const FooterButton = styled(LinkButton)`
  margin-bottom : 12rem;
`


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const formattedDate = new Intl.DateTimeFormat(undefined, {month : 'long', day:'numeric', year : 'numeric'}).format(new Date(frontmatter.date));

  return (
    <Layout>
      <SEO 
        title={frontmatter.title} 
        image={frontmatter.thumbnail}
        author={frontmatter.author}
        description={frontmatter.title}
        type="article"
        path={makeBlogArticlePath(frontmatter.title)}
      />
      <DesaturatedMainImageSection image={frontmatter.thumbnail}>
        <MainTitle
          isStatic={true}
          strings={[
            frontmatter.title
          ]}
          />
      </DesaturatedMainImageSection>

      <MainSection>
          <ArticleContent
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <SeparatorContainer>
            {
              frontmatter.author ? 
                <React.Fragment>
                  {
                    frontmatter.authorAvatar ? 
                      <Avatar src={frontmatter.authorAvatar} /> :
                      null
                  }
                  <SeparatorText>{frontmatter.author}</SeparatorText>
                </React.Fragment> :
                null
            }
            <Separator className={!frontmatter.author ? 'wide' : ''}/>
            <SeparatorText>{formattedDate}</SeparatorText>
          </SeparatorContainer>
      </MainSection>
      <ContactSection>
        <h2>Une question, un projet ?</h2>
        <FooterButton to="/contact">Contactez nous</FooterButton>
      </ContactSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        date
        title
        thumbnail
        author
        authorAvatar
      }
    }
  }
`